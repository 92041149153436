import moment from 'moment';

export const truncateString = (string = '', len = 30, canPassLimit = false) => {
  const searchChars = ' .,;{}[]='.split('');
  let curr = -1;
  searchChars.forEach((char) => {
    if (curr !== -1) return;
    curr = string.lastIndexOf(char, len);
    if (curr === -1 && canPassLimit) curr = string.indexOf(char, len);
  });
  if (curr === -1) curr = len;
  return [string.substring(0, curr).trim(), string.substr(curr).trim()];
};

export const truncateFullName = (full_name) => {
  if (full_name) {
    let firstName;
    let lastName = '';
    const nameArr = full_name.split(' ');
    if (nameArr.length >= 3) {
      lastName = nameArr.slice(-2, nameArr.length).join(' ');
      //skip lastName
      nameArr.pop();
      nameArr.pop();
      firstName = nameArr.join(' ');
      return { firstName, lastName };
    } else if (nameArr.length === 2) {
      firstName = nameArr[0];
      lastName = nameArr[nameArr.length - 1];
      return { firstName, lastName };
    } else {
      firstName = nameArr[0];
      return { firstName };
    }
  }
  return {};
};

export const getBirthDateFromCI = (identifier) => {
  const month = identifier.substr(2, 2);
  const day = identifier.substr(4, 2);
  const century = identifier.at(6);
  
  const before1900 = `18${identifier.substr(0, 2)}`;
  const after1900 = (century >= 0 && century <= 5)
    ? `19${identifier.substr(0, 2)}`
    : `20${identifier.substr(0, 2)}`;
  
  const year = (century === 9) ? before1900 : after1900;
  return moment(`${year}-${month}-${day}`).toDate();
};

export const validateBirthDateFromCI = (identifier) => {
  const birthDate = getBirthDateFromCI(identifier);
  return moment().isAfter(birthDate, 'day');
};

export const createMarkup = (value) => ({ __html: value });

/**
 * @param {string} value
 * @return {string}
 */
export const sanitizeValue = (value = '') => {
  return value
    // Remove spaces before and after the string
    .trim()
    // Remove multiple whitespaces
    .replace(/\s\s+/g, ' ')
    // Remove linebreak. https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
    .replace(/(\r\n|\n|\r)/gm, '');
};

/**
 * The function takes a string as an argument, normalizes it to the NFD form, and then replaces all the
 * diacritics with an empty string.
 * @param {string} string - The string to be normalized.
 * @returns {string} normalized representation of the string.
 */
export const stringNormalizeForm = (string) => {
  return string.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

/**
 * @param {string} str
 * @param {string} otherStr
 * @return {boolean}
 */
export const stringIncludesNormalized = (str, otherStr) => {
  return stringNormalizeForm(str)
    .toLowerCase()
    .includes(
      stringNormalizeForm(otherStr)
        .toLowerCase()
    );
};

export const getFullNameFromFields = ({ firstName, lastName, secondLastName }) => {
  let fullName = '';
  if (firstName || lastName || secondLastName) {
    if (firstName) fullName += `${firstName} `;
    if (lastName) fullName += `${lastName} `;
    if (secondLastName) fullName += `${secondLastName} `;
  }
  return fullName.trim();
};

export default {
  truncateString,
  truncateFullName,
  getBirthDateFromCI,
  validateBirthDateFromCI,
  createMarkup,
  sanitizeValue,
  stringNormalizeForm,
  stringIncludesNormalized,
  getFullNameFromFields,
};
// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components';

const HEX_REGEX = /^#[0-9A-F]{6}$/i;

const sizes = {
  tablet: 360,
  desktop: 992,
  standardDesktop: 1366,
  giant: 1920,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes)
  .reduce((accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
            @media (min-width: ${ emSize }em) {
                ${ css(...args) }
            }
        `;
    return accumulator;
  }, {});

export const field = (themeItem, field, defaultValue) => themeItem[field] || defaultValue;

export const convertHexToRGB = (hex, opacity) => {
  // Check if hex is a valid hex value
  if (!HEX_REGEX.test(hex))
    throw new Error('Invalid hex value');

  // Check if opacity is a valid value
  if (opacity < 0 || opacity > 1)
    throw new Error('Opacity must be between 0 and 1');

  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${ r }, ${ g }, ${ b }, ${ opacity })`;
};

export const convertHexToRGBObj = (hex, opacity) => {
  hex = hex.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b, a: 1 };
};

export const componentToHex = (component) => {
  let hex = component.toString(16);
  return hex.length === 1 ? `0${ hex }` : hex;
};

export const convertRGBToHex = (rgb) => `#${ componentToHex(rgb.r) }${ componentToHex(rgb.g) }${ componentToHex(rgb.b) }`;

export default {
  media,
  field,
  convertHexToRGB,
  convertHexToRGBObj,
  componentToHex,
  convertRGBToHex,
};
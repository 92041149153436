import {
  TRANSACTION_STATUS_OPTIONS,
  TRANSACTION_SUBTYPE_OPTIONS,
  TRANSACTION_TYPE_OPTIONS,
} from '../../actions/nomenclator';

const initialState = {
  nomenclator: {
    user_kyc_status: [
      { value: 'verified', label: 'Verified' },
      { value: 'failed_verified', label: 'Verified Failed' },
      { value: 'not_verified', label: 'Not Verified' },
    ],
    user_block_status: [
      { value: 1, label: 'Blocked' },
      { value: 0, label: 'Unblocked' },
    ],
    user_card_block_status: [
      { value: 1, label: 'Blocked' },
      { value: 0, label: 'Unblocked' },
    ],
    validated_user: [
      { value: 1, label: 'Verified' },
      { value: 0, label: 'Pending' },
    ],
    user_document_types: [
      { value: 'passport', label: 'Passport' },
      { value: 'other', label: 'Other' },
    ],
    transaction_status: [
      { value: '', label: 'Select a state' },
      ...TRANSACTION_STATUS_OPTIONS,
    ],
    transaction_type: [
      { value: '', label: 'Select a type' },
      ...TRANSACTION_TYPE_OPTIONS,
    ],
    cash_out_method: [
      { value: '', label: 'Select a subtype' },
      ...TRANSACTION_SUBTYPE_OPTIONS.CASH_OUT_TRANSACTION,
    ],
    token_buy_method: [
      { value: '', label: 'Select a subtype' },
      ...TRANSACTION_SUBTYPE_OPTIONS.TOKENS_BUY,
    ],
    top_ups_method: [
      { value: '', label: 'Select a subtype' },
      ...TRANSACTION_SUBTYPE_OPTIONS.TOPUP_RECHARGE,
    ],
    payment_request_method: [
      { value: '', label: 'Select a subtype' },
      ...TRANSACTION_SUBTYPE_OPTIONS.PAYMENT_REQUEST,
    ],
    currencies: [
      { value: '', label: 'Select a currency' },
      { value: 'EUR', label: '[EUR] Euro' },
      { value: 'USD', label: '[USD] US Dollar' },
      { value: 'CAD', label: '[CAD] Canadian Dollar' },
      { value: 'CUC', label: '[CUP] Cuban Peso' },
      { value: 'EUR_FIAT', label: '[EUR-FIAT] Euro' },
      { value: 'USD_FIAT', label: '[USD-FIAT] US Dollar' },
      { value: 'CAD_FIAT', label: '[CAD-FIAT] Canadian Dollar' },
      { value: 'CUC_FIAT', label: '[CUP-FIAT] Cuban Peso' },
    ],
    severity_tickets: [
      { value: 'Minor', label: 'Minor' },
      { value: 'Major', label: 'Major' },
      { value: 'Critical', label: 'Critical' },
    ],
    type_tickets: [
      { value: 'Bug', label: 'Bug' },
      { value: 'Change request', label: 'Change request' },
    ],
    status_user: [
      { value: 'Invited', label: 'Invited' },
      { value: 'Pending', label: 'Pending' },
      { value: 'Activated', label: 'Activated' },
      { value: 'Deleted', label: 'Deleted' },
      { value: 'To Verify', label: 'Ready to Verify' },
    ],
    notification_provider_type: [
      { value: 'sms', label: 'SMS' },
      { value: 'email', label: 'EMAIL' },
      { value: 'push', label: 'PUSH' },
      { value: 'system', label: 'SYSTEM' },
    ],
    status_advertisement: [
      { value: 'ACTIVE', label: 'Active' },
      { value: 'INACTIVE', label: 'Inactive' },
    ],
    notification_frecuency: [
      { value: 'Instant', label: 'Instant' },
      { value: 'Once a day', label: 'Once a day' },
      { value: 'Once a week', label: 'Once a week' },
    ],
    admin_roles: [
      { value: 'ROLE_SYSTEM_ADMIN', label: 'System admin' },
      { value: 'ROLE_ADMIN_ALMIGHTY', label: 'Global System admin' },
    ],
    static_content_types: [
      { value: 'TERMS_CONDITIONS', label: 'Terms & Conditions' },
      { value: 'REFUND_POLICY', label: 'Refund Policy' },
      { value: 'PRIVACY_POLICY', label: 'Privacy policy' },
      { value: 'ABOUT_US', label: 'About US' },
      { value: 'FAQ', label: 'FAQ' },
    ],
    static_content_languages: [
      { value: 'EN', label: 'English' },
      { value: 'ES', label: 'Español' },
      { value: 'FR', label: 'Français' },
    ],
    metrics: {
      'MAX': 'Max',
      'MIN': 'Min',
      'COUNT': 'Count',
      'UNIQUE_COUNT': 'Distinc',
      'AVG': 'Average',
      'STD': 'Standard deviation',
      'SUM': 'Sum',
      'SUMQ': 'Square Sum',
      'VAR': 'Standard variation',
    },
    filters: {
      'TERM': 'Term',
      'TERMS': 'Terms',
      'REGEX': 'Regex',
      'WILDCARD': 'Wilcard',
      'RANGE': 'Range',
    },
    thunes_transactions_type: [
      { value: 'C2C', label: 'Client to Client' },
      { value: 'C2B', label: 'Client to Business' },
      { value: 'B2B', label: 'Business to Business' },
      { value: 'B2C', label: 'Business to Client' },
    ],
    topup_providers: [
      { value: 'dtone-dvs', label: 'DTOne DVS' },
      { value: 'dtone', label: 'DTOne' },
      { value: 'oyemobile', label: 'Oyemobile' },
    ],
    unit_of_time: [
      { value: 'years', label: 'Year(s)' },
      { value: 'months', label: 'Month(s)' },
      { value: 'weeks', label: 'Week(s)' },
      { value: 'days', label: 'Day(s)' },
      { value: 'hours', label: 'Hour(s)' },
      { value: 'minutes', label: 'Minute(s)' },
      { value: 'seconds', label: 'Second(s)' },
    ],
    currency_codes: [
      { code: 'EUR', label: 'Euro', symbol: 'EUR' },
      { code: 'USD', label: 'US Dollar', symbol: 'USD' },
      { code: 'CAD', label: 'Canadian Dollar', symbol: 'CAD' },
      { code: 'CUC', label: 'Cuban Peso', symbol: 'CUP' },
      { code: 'CUP', label: 'Cuban Peso', symbol: 'CUP' },
      { code: 'DUC_EUR', label: 'DUC Euro', symbol: 'DUC_EUR' },
      { code: 'DUC_USD', label: 'DUC US Dollar', symbol: 'DUC_USD' },
      { code: 'DUC_CAD', label: 'DUC Canadian Dollar', symbol: 'DUC_CAD' },
      { code: 'DUC_CUC', label: 'DUC Cuban Peso', symbol: 'DUC_CUP' },
      { code: 'DUC_CUP', label: 'DUC Cuban Peso', symbol: 'DUC_CUP' },
      { code: 'EUR_FIAT', label: '[FIAT] Euro', symbol: 'EUR_FIAT' },
      { code: 'USD_FIAT', label: '[FIAT] US Dollar', symbol: 'USD_FIAT' },
      { code: 'CAD_FIAT', label: '[FIAT] Canadian Dollar', symbol: 'CAD_FIAT' },
      { code: 'CUC_FIAT', label: '[FIAT] Cuban Peso', symbol: 'CUP_FIAT' },
    ],
    status_alarm: [
      { value: '1', label: 'Active' },
      { value: '2', label: 'Acknowledged' },
      { value: '3', label: 'Return to Normal' },
    ],
    priority_alarm: [
      { value: '5', label: 'Over High' },
      { value: '4', label: 'High' },
      { value: '3', label: 'Medium' },
      { value: '2', label: 'Normal' },
      { value: '1', label: 'Low' },
      { value: '0', label: 'Below low' },
    ],
    type_alarm: [
      { value: 'Users', label: 'Users' },
      { value: 'System', label: 'System' },
      { value: 'Process', label: 'Process' },
      { value: 'Validations', label: 'Validations' },
      { value: 'Transaction', label: 'Transaction' },
      { value: 'Rule', label: 'Rule' },
    ],
    payments_provider: [
      { value: 'default', label: 'Default' },
      { value: 'moneris', label: 'Moneris' },
      { value: 'stripe', label: 'Stripe' },
      { value: 'square', label: 'Square' },
      { value: 'nuvei', label: 'Nuvei' },
      { value: 'cardconnect', label: 'First Data (card connect)' },
    ],
    payments_provider_currency: [
      { value: 'USD', label: '[USD] US Dollar' },
      { value: 'CAD', label: '[CAD] Canadian Dollar' },
      { value: 'EUR', label: '[EUR] Euro' },
    ],
    transaction_fee_source: [
      { value: 'range', label: 'Use Amount Range' },
      { value: 'percent', label: 'Use Percent' },
    ],
    balance_sync: [
      { value: 1, label: 'Only Synchronized' },
      { value: 0, label: 'Only not Synchronized' },
    ],
    balance_with_amount: [
      { value: 0, label: 'Only with amount' },
      { value: 1, label: 'Only without amount' },
    ],
    document_type: [
      { value: 'DNI', label: 'National ID' },
      { value: 'EUI', label: 'European ID' },
      { value: 'DRV', label: 'Drive Licence' },
      { value: 'PAS', label: 'Passport' },
      { value: 'RES', label: 'Resident card' },
      { value: 'CED', label: 'Cedula' },
      { value: 'OTH', label: 'Other' },
    ],
    person_genre: [
      { value: 'M', label: 'Male' },
      { value: 'F', label: 'Female' },
    ],
    recurrence_type: [
      { value: 'expression', label: 'Expression' },
      // { value: 'date', label: 'Date' },
      // { value: 'recurrence', label: 'Recurrence' },
    ],
  },
};

export default (state = initialState) => {
  return state;
};

// ======================================= TRANSACTION TYPE ======================================= //
export const TRANSACTION_TYPES = {
  PAYMENT_REQUEST: 'PAYMENT_REQUEST',
  EMAIL_PAYMENT_REQUEST: 'EMAIL_PAYMENT_REQUEST',
  MLC_PAYMENT_REQUEST: 'MLC_PAYMENT_REQUEST',
  TOKENS_BUY: 'TOKENS_BUY',
  CRYPTO_TOKEN_BUY: 'CRYPTO_TOKEN_BUY',
  P2P_TRANSFER: 'P2P_TRANSFER',
  ADMIN_TRANSFER: 'ADMIN_TRANSFER',
  TOKENS_EXCHANGE: 'TOKEN_EXCHANGE',
  TOPUP_RECHARGE: 'TOPUP_RECHARGE',
  CASH_OUT_TRANSACTION: 'CASH_OUT_TRANSACTION',
  FAUCET_ADD_TOKEN: 'FAUCET_ADD_TOKEN',
  FAUCET_SUB_TOKEN: 'FAUCET_SUB_TOKEN',
  CASH_ADD_AGENT: 'CASH_ADD_AGENT',
  CASH_SUB_AGENT: 'CASH_SUB_AGENT',
  REFERRAL_TRANSACTION: 'REFERRAL_TRANSACTION',
  ROLLBACK_TRANSACTION: 'ROLLBACK_TRANSACTION',
  DYNAMIC_PAYMENT: 'DYNAMIC_PAYMENT',
  REFUND_TRANSACTION: 'REFUND_TRANSACTION',
  GIFT_CARD_ADD_TOKEN: 'GIFT_CARD_ADD_TOKEN',
  GIFT_CARD_SUB_TOKEN: 'GIFT_CARD_SUB_TOKEN',
};

export const TRANSACTION_TYPE_MAP = {
  [TRANSACTION_TYPES.PAYMENT_REQUEST]: 'Payment Requests',
  [TRANSACTION_TYPES.EMAIL_PAYMENT_REQUEST]: 'Email Payment Requests',
  [TRANSACTION_TYPES.MLC_PAYMENT_REQUEST]: 'MLC Payment Requests',
  [TRANSACTION_TYPES.DYNAMIC_PAYMENT]: 'Dynamic Payment',
  [TRANSACTION_TYPES.TOKENS_BUY]: 'Tokens buy',
  [TRANSACTION_TYPES.CRYPTO_TOKEN_BUY]: 'Cryptocurrency Tokens buy',
  [TRANSACTION_TYPES.P2P_TRANSFER]: 'P2P Transfer',
  [TRANSACTION_TYPES.TOKENS_EXCHANGE]: 'Tokens Exchange',
  [TRANSACTION_TYPES.TOPUP_RECHARGE]: 'Top-Ups Transfers',
  [TRANSACTION_TYPES.CASH_OUT_TRANSACTION]: 'Cash-Out Transactions',
  [TRANSACTION_TYPES.FAUCET_ADD_TOKEN]: 'Faucet Token Added',
  [TRANSACTION_TYPES.FAUCET_SUB_TOKEN]: 'Faucet Token Subtracted',
  [TRANSACTION_TYPES.CASH_ADD_AGENT]: 'Cash To Agent Added',
  [TRANSACTION_TYPES.CASH_SUB_AGENT]: 'Cash To Agent Subtracted',
  [TRANSACTION_TYPES.REFERRAL_TRANSACTION]: 'Referral transaction',
  [TRANSACTION_TYPES.ROLLBACK_TRANSACTION]: 'Rollback Transactions',
  [TRANSACTION_TYPES.REFUND_TRANSACTION]: 'Refund Transaction',
  [TRANSACTION_TYPES.GIFT_CARD_ADD_TOKEN]: 'Gift Card Token Added',
  [TRANSACTION_TYPES.GIFT_CARD_SUB_TOKEN]: 'Gift Card Token Subtracted',
};

export const TRANSACTION_TYPE_OPTIONS = Object
  .entries(TRANSACTION_TYPE_MAP)
  .map(([value, label]) => ({ value, label }));

// ------- TRANSACTION SUBTYPE OPTIONS ------- //
export const CASHOUT_METHODS = {
  BANK_ACCOUNT_TRANSACTION: 'BANK_ACCOUNT_TRANSACTION',
  EMAIL_MONEY_TRANSACTION: 'EMAIL_MONEY_TRANSACTION',
  CREDIT_CARD_TRANSACTION: 'CREDIT_CARD_TRANSACTION',
  MLC_CREDIT_CARD_TRANSACTION: 'MLC_CREDIT_CARD_TRANSACTION',
  DELIVERY_TRANSACTION: 'DELIVERY_TRANSACTION',
  DELIVERY_TRANSACTION_USD: 'DELIVERY_TRANSACTION_USD',
  DELIVERY_TRANSACTION_EUR: 'DELIVERY_TRANSACTION_EUR',
  THUNES_TRANSACTION: 'THUNES_TRANSACTION',
};

export const TOKENBUY_METHODS = {
  CREDIT_CARD_TRANSACTION: 'CREDIT_CARD_TRANSACTION',
  APPLE_PAY_TRANSACTION: 'APPLE_PAY_TRANSACTION',
  GOOGLE_PAY_TRANSACTION: 'GOOGLE_PAY_TRANSACTION',
  CRYPTO_TRANSACTION: 'CRYPTO_TRANSACTION',
};

export const TOPUP_METHODS = {
  DIRECT_TOPUP: 'DIRECT_TOPUP',
  SCHEDULED_TOPUP: 'SCHEDULED_TOPUP',
};

export const PAYMENT_REQUEST_METHODS = {
  INTERNAL: 'INTERNAL',
  PAYMENT_LINK: 'PAYMENT_LINK',
};

export const CASHOUT_METHOD_MAP = {
  [CASHOUT_METHODS.CREDIT_CARD_TRANSACTION]: 'To credit card (CUP)',
  [CASHOUT_METHODS.MLC_CREDIT_CARD_TRANSACTION]: 'To credit card (MLC)',
  [CASHOUT_METHODS.DELIVERY_TRANSACTION]: 'To delivery (CUP)',
  [CASHOUT_METHODS.DELIVERY_TRANSACTION_USD]: 'To delivery (USD)',
  [CASHOUT_METHODS.DELIVERY_TRANSACTION_EUR]: 'To delivery (EUR)',
  [CASHOUT_METHODS.THUNES_TRANSACTION]: 'To Thunes',
  [CASHOUT_METHODS.EMAIL_MONEY_TRANSACTION]: 'Email money transfer',
  [CASHOUT_METHODS.BANK_ACCOUNT_TRANSACTION]: 'To bank account',
};

export const TOKENBUY_METHOD_MAP = {
  [TOKENBUY_METHODS.CREDIT_CARD_TRANSACTION]: 'Using Credit Card',
  [TOKENBUY_METHODS.APPLE_PAY_TRANSACTION]: 'Using Apple Pay',
  [TOKENBUY_METHODS.GOOGLE_PAY_TRANSACTION]: 'Using Google Pay',
  [TOKENBUY_METHODS.CRYPTO_TRANSACTION]: 'Using Cryptocurrency',
};

export const TOPUP_METHOD_MAP = {
  [TOPUP_METHODS.DIRECT_TOPUP]: 'Direct Topup',
  [TOPUP_METHODS.SCHEDULED_TOPUP]: 'Scheduled Topup',
};

export const PAYMENT_REQUEST_METHOD_MAP = {
  [PAYMENT_REQUEST_METHODS.INTERNAL]: 'Internal request',
  [PAYMENT_REQUEST_METHODS.PAYMENT_LINK]: 'Payment Link request',
};

export const TRANSACTION_SUBTYPE_OPTIONS = {
  CASH_OUT_TRANSACTION: Object
    .entries(CASHOUT_METHOD_MAP)
    .map(([value, label]) => ({ value, label })),
  TOKENS_BUY: Object
    .entries(TOKENBUY_METHOD_MAP)
    .map(([value, label]) => ({ value, label })),
  TOPUP_RECHARGE: Object
    .entries(TOPUP_METHOD_MAP)
    .map(([value, label]) => ({ value, label })),
  PAYMENT_REQUEST: Object
    .entries(PAYMENT_REQUEST_METHOD_MAP)
    .map(([value, label]) => ({ value, label })),
};

// ====================================== TRANSACTION STATUS ====================================== //
export const TRANSACTION_STATUS = {
  PENDING: 'pending',
  QUEUED: 'queued',
  PROCESSED: 'processed',
  REQUESTED: 'requested',
  RECEIVED: 'received',
  CONFIRMED: 'confirmed',
  ACCEPTED: 'accepted',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  FAILED: 'failed',
  EXPIRED: 'expired',
  REFUNDED: 'refunded',
  PENDING_REFUND: 'pending_refund',
  PARTIAL_REFUNDED: 'partial_refunded',
  PENDING_APPROVAL: 'pending_approval',
  // Third-party Status
  THIRD_PARTY_PENDING: 'third_party_pending',
  THIRD_PARTY_PROCESSED: 'third_party_processed',
  THIRD_PARTY_UNRESOLVED: 'third_party_unresolved',
  THIRD_PARTY_COMPLETED: 'third_party_completed',
  THIRD_PARTY_CANCELED: 'third_party_canceled',
  // Specific Status
  REMESITA_PROCESSED: 'remesita_processed',
  TAMIPRO_PROCESSED: 'tamipro_processed',
  THUNES_PROCESSED: 'thunes_processed',
  THUNES_COMPLETED: 'thunes_completed',
  COINBASE_PENDING: 'coinbase_pending',
  COINBASE_UNRESOLVED: 'unresolved',
  COINBASE_PROCESSED: 'coinbase_processed',
  MLC_ACCEPTED: 'mlc_accepted'
};

export const TRANSACTION_STATUS_MAP = {
  [TRANSACTION_STATUS.PENDING]: 'Pending',
  [TRANSACTION_STATUS.QUEUED]: 'To process',
  [TRANSACTION_STATUS.PROCESSED]: 'Processed',
  [TRANSACTION_STATUS.REQUESTED]: 'Requested',
  [TRANSACTION_STATUS.RECEIVED]: 'Received',
  [TRANSACTION_STATUS.CONFIRMED]: 'Confirmed',
  [TRANSACTION_STATUS.ACCEPTED]: 'Accepted',
  [TRANSACTION_STATUS.APPROVED]: 'Approved',
  [TRANSACTION_STATUS.CANCELED]: 'Canceled',
  [TRANSACTION_STATUS.REJECTED]: 'Rejected',
  [TRANSACTION_STATUS.FAILED]: 'Failed',
  [TRANSACTION_STATUS.EXPIRED]: 'Expired',
  [TRANSACTION_STATUS.REFUNDED]: 'Refunded',
  [TRANSACTION_STATUS.PENDING_REFUND]: 'Pending Refund',
  [TRANSACTION_STATUS.PARTIAL_REFUNDED]: 'Partial Refunded',
  [TRANSACTION_STATUS.PENDING_APPROVAL]: 'Pending by Approval',
  // Third-party Status
  [TRANSACTION_STATUS.THIRD_PARTY_PENDING]: 'Pending - Third-party',
  [TRANSACTION_STATUS.THIRD_PARTY_PROCESSED]: 'Processed - Third-party',
  [TRANSACTION_STATUS.THIRD_PARTY_UNRESOLVED]: 'Unresolved - Third-party',
  [TRANSACTION_STATUS.THIRD_PARTY_COMPLETED]: 'Completed - Third-party',
  [TRANSACTION_STATUS.THIRD_PARTY_CANCELED]: 'Canceled - Third-party',
  // Specific Status
  [TRANSACTION_STATUS.REMESITA_PROCESSED]: 'Processed - Remesita',
  [TRANSACTION_STATUS.TAMIPRO_PROCESSED]: 'Processed - Tamipro',
  [TRANSACTION_STATUS.THUNES_PROCESSED]: 'Processed - Thunes',
  [TRANSACTION_STATUS.THUNES_COMPLETED]: 'Completed - Thunes',
  [TRANSACTION_STATUS.COINBASE_PENDING]: 'Pending - Coinbase',
  [TRANSACTION_STATUS.COINBASE_PROCESSED]: 'Processed - Coinbase',
  [TRANSACTION_STATUS.COINBASE_UNRESOLVED]: 'Unresolved - Coinbase',  
  [TRANSACTION_STATUS.MLC_ACCEPTED]: 'Accepted-MLC'
};

export const TRANSACTION_STATUS_OPTIONS = Object
  .entries(TRANSACTION_STATUS_MAP)
  .map(([value, label]) => ({ value, label }));

const _orFilter = (data, filter = {}) => {
  return !Object.keys(filter).some((key) => {
    return data[key] === filter[key];
  });
};

export const singleFilter = (key, data, filter) => {
  if (key === '__OR')
    return !_orFilter(data, filter.__OR);
  const valueToCompare = filter[key];
  if (Array.isArray(valueToCompare))
    return valueToCompare.some(element => element === data[key]);
  return data[key] !== valueToCompare;
};

export const filterCheck = (data, filter = {}) => {
  return !Object
    .keys(filter)
    .some(key => singleFilter(key, data, filter));
};

export default {
  singleFilter,
  filterCheck,
};
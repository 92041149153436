import _isEmpty from 'lodash/isEmpty';
import Payment from 'payment';

class CardVerificationService {
  
  cleanValue(value) {
    if (value) {
      return value.replace(/\D/g, '');
    }
    return '';
  }
  
  cardExpiryVal(value) {
    return Payment.fns.cardExpiryVal(value);
  }
  
  validateCardCVC(cvc, type) {
    return Payment.fns.validateCardCVC(cvc, type);
  }
  
  async _getTypeByRegex(value, regexObject, regexKeys) {
    const cleanValue = this.cleanValue(value);
    
    return regexKeys
      .reduce((prev, key) => {
        const regex = new RegExp(regexObject[key]);
        return regex.test(cleanValue) ? key.replace('_CARD', '').toLowerCase() : prev;
      }, 'default');
  }
  
  cardType(cardNumber, regexObject, regexKeys) {
    if (!_isEmpty(regexObject) && !_isEmpty(regexKeys))
      return this._getTypeByRegex(cardNumber, regexObject, regexKeys);
    return Payment.fns.cardType(cardNumber);
  }
  
  validateMaskedRegex(cardNumber) {
    const startOfRegex = /^(\d{1,4})(\s?\*{3,4}){1,3}$/g;
    const endOfRegex = /^(\*{3,4}\s?){1,3}(\d{1,4})$/g;
    const containsRegex = /^(\d{1,4})\s?(\*{3,4}\s?)+(\d{1,4})$/g;
    
    return startOfRegex.test(cardNumber) || endOfRegex.test(cardNumber) || containsRegex.test(cardNumber);
  }
  
  _validateWithRegex(value, regexObject, regexKeys = []) {
    const cleanValue = this.cleanValue(value);
    
    return regexKeys
      .map(key => (new RegExp(regexObject[key])).test(cleanValue))
      .some(item => item === true);
  }
  
  validateCardNumber(cardNumber, regexObject, regexKeys) {
    const validByLibrary = Payment.fns.validateCardNumber(cardNumber);
    const validByMasked = this.validateMaskedRegex(cardNumber);
    if (!_isEmpty(regexObject) && !_isEmpty(regexKeys)) {
      const validByRegex = this._validateWithRegex(cardNumber, regexObject, regexKeys);
      console.log({ validByLibrary }, { validByRegex });
      return validByLibrary || validByRegex;
    }
    return validByLibrary || validByMasked;
  }
  
  formatCardNumber(cardNumber) {
    if (Payment.fns.validateCardNumber(cardNumber)) {
      return Payment.fns.formatCardNumber(cardNumber);
    } else {
      const withOutSpaceValue = this.cleanValue(cardNumber);
      const defaultFormat = /(\d{1,4})/g;
      const elements = withOutSpaceValue.match(defaultFormat);
      
      if (elements && elements.length) return elements.join(' ');
      return cardNumber;
    }
  }
}

export default new CardVerificationService();

import { default as axios } from 'axios';
import { FRONTEND_DEVICE_ID } from './utils';

export class AxiosService {
  constructor(headers, baseURL) {
    const token = this.getStorageItem('duales_access_token');
    let service = axios.create({
      baseURL: baseURL || process.env.REACT_APP_BACKEND_URL,
      headers: {
        ...headers,
        'accept-language': window.navigator.userLanguage || window.navigator.language,
        'authorization': token ? `Bearer ${token}` : '',
        'X-DeviceID': FRONTEND_DEVICE_ID,
        'x-appVersion': 'adminSystem'
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }
  
  getStorageItem(key) {
    return window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  }
  
  handleSuccess(response) {
    return response;
  }
  
  handleError = (error) => {
    return Promise.reject({ status: error.response.status, response: error.response });
  };
  
  get(path) {
    return this.service.get(path);
  }
  
  patch(path, payload) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
  
  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      data: payload
    });
  }
}

import ReactGA from 'react-ga';

/**
 * Init Google Analytics Tracking
 */
export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGCODE,
    (process.env.NODE_ENV !== 'production') ? {
      debug: true,
      gaOptions: {cookieDomain: 'none'}
    } : {});
};

/**
 * PageView - Google Analytics Page View Tracking
 * @param pathname route page
 * @constructor
 */
export const PageView = (pathname) => {
  ReactGA.set({page: pathname});
  ReactGA.pageview(pathname);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label = '') => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

import _hasIn from 'lodash/hasIn';
import _isDate from 'lodash/isDate';
import _isEmpty from 'lodash/isEmpty';

export const arrayToObject = (array, keyField) => {
  return array.reduce((obj, item) => {
    if (_hasIn(item, keyField))
      obj[item[keyField]] = item;
    return obj;
  }, {});
};

export const cleanEmptyKeys = (obj) => {
  Object.entries(obj)
    .forEach(([key, val]) => {
      if (val && typeof val === 'object' && !_isDate(val))
        cleanEmptyKeys(val);
      else if (val == null || val === '')
        delete obj[key];
      if (val && typeof val === 'object' && !_isDate(val) && _isEmpty(val))
        delete obj[key];
    });
  return obj;
};

/**
 * Flattens an object.
 *
 * @param {object} unflattened - the object to flatten
 * @param {string} delimiter - the delimiter to be used when flattening the object. Defaults to '.'.
 * @returns {object} - The flattened object, empty if provided object is undefined
 * @public
 */
export const flatten = (unflattened, delimiter = '.') => {
  if (unflattened === undefined || unflattened === null)
    return unflattened;
  
  return Object
    .entries(unflattened)
    .reduce((accumulator, [key, value]) => {
      if (typeof value === 'object' && value !== null && !_isEmpty(value) && !_isDate(value)) {
        const flatObject = flatten(value, delimiter);
        Object
          .entries(flatObject)
          .forEach(([subKey, subValue]) => (
            // append to create new key value and assign it's value
            accumulator[`${key}${delimiter}${subKey}`] = subValue),
          );
      } else {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
};

const explodeProperty = (currUnflattened, key, flattenedObj, delimiter) => {
  const keys = key.split(delimiter);
  const value = flattenedObj[key];
  const lastKeyIndex = keys.length - 1;
  for (let idx = 0; idx < lastKeyIndex; idx++) {
    const currKey = keys[idx];
    let nextKeyVal;
    if (idx === 0 && currKey === '__proto__') {
      return;
    }
    if (!(currKey in currUnflattened)) {
      nextKeyVal = parseInt(keys[idx + 1], 10);
      currUnflattened[currKey] = isNaN(nextKeyVal) ? {} : [];
    }
    currUnflattened = currUnflattened[currKey];
  }
  currUnflattened[keys[lastKeyIndex]] = value;
};

/**
 * Unflattens an object with compressed keys.
 *
 * @param {object} flattened - object to unflatten
 * @param {string} delimiter - the delimiter to be used when unflattening the object. Defaults to '.'.
 * @returns {object} - The unflattened object, empty if provided object is undefined.
 * @public
 */
export const unflatten = (flattened, delimiter = '.') => {
  if (flattened === undefined || flattened === null)
    return flattened;
  
  return Object
    .keys(flattened)
    .reduce((acc, key) => {
      explodeProperty(acc, key, flattened, delimiter);
      return acc;
    }, {});
};

export default {
  arrayToObject,
  cleanEmptyKeys,
  flatten,
  unflatten,
};
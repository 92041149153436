/**
 * Title
 * description
 * @author Yadian Fernandez
 * @date 2/21/2019
 *
 */

export default ['jpg', 'jpeg', 'gif', 'png', 'txt', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'ppt', 'pptx', 'pps', 'odt', 'ods', 'odp', 'zip', 'rar', '7z', 'psd', 'ai', 'mp4', 'mpeg', 'webm', 'avi', 'zip', 'rar', 'tar', 'gz', 'sketch'];



/**
 * Title
 * description
 * @author Reynaldo Barcelo
 * @date 4/3/2019
 *
 */

import TYPES from './file-extensions';

export {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  GOOGLE_API_KEY,
  ROAD,
  SATELLITE,
  TERRAIN
} from './map-constants';

export const FILE_TYPES = TYPES;


/**
 * @param {HTMLInputElement} inputElement
 * @param {number} caretPos
 * @return {boolean}
 */
export const setCaretPosition = (inputElement, caretPos) => {
  if (!inputElement)
    return false;
  
  if (typeof inputElement.createTextRange === 'function') {
    const range = inputElement.createTextRange();
    range.move('character', caretPos);
    range.select();
    return true;
  }
  // (inputElement.selectionStart === 0 added for Firefox bug)
  if (inputElement.selectionStart || inputElement.selectionStart === 0) {
    inputElement.focus();
    inputElement.setSelectionRange(caretPos, caretPos);
    return true;
  }
  // fail city, fortunately this never happens (as far as I've tested) :)
  // inputElement.focus();
  return false;
};

export const getWidthOfText = (txt, fontname, fontsize) => {
  if (getWidthOfText.e === undefined) {
    getWidthOfText.e = document.createElement('span');
    getWidthOfText.e.style.visibility = 'hidden';
    document.body.appendChild(getWidthOfText.e);
  }
  getWidthOfText.e.style.fontSize = fontsize;
  getWidthOfText.e.style.fontFamily = fontname;
  getWidthOfText.e.innerText = txt;
  return getWidthOfText.e.offsetWidth;
};

export const isEllipsisActive = (e) => {
  return (e.offsetWidth < e.scrollWidth);
};

export const createMarkup = (value) => {
  return { __html: value };
};

export default {
  setCaretPosition,
  getWidthOfText,
  isEllipsisActive,
  createMarkup,
};

import { camelCase } from '../stringCase';
import { allCountries } from './data/country-regional-codes';

export const getCountryInfo = (searchTerm) => {
  const rawCountryInfo = allCountries.find(
    item => item.name === searchTerm || item['alpha-2'] === searchTerm.toUpperCase() || item['alpha-3'] === searchTerm.toUpperCase());
  const countryInfo = {};
  if (rawCountryInfo) Object.entries(rawCountryInfo).forEach(([key, value]) => (countryInfo[camelCase(key)] = value));
  return countryInfo;
};

export { allCountries } from './data/country-regional-codes';

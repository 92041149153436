import Config from '../config';
import { FILE_TYPES } from './constants';

const bucket = Config.s3.BUCKET;
const folder = Config.s3.FOLDERNAME;
const bucketPath = `https://s3.amazonaws.com/${bucket}`;

export const checkedLimitSize = (max, size) => {
  return size / (1024 * 1024) >= max;
};

export const checkFileExtensions = (extension, types) => {
  if (!types) types = FILE_TYPES;
  return types.some(item => item === extension);
};

export const getFileNameBroken = (name) => {
  return name.substr(name.lastIndexOf('\\') + 1).split('.');
};

export const getFileExtension = (name) => {
  if (!name) return null;
  const temporal = name.split('.');
  return (temporal.length > 1) ? temporal.at(-1) : null;
};

export const formatSize = size => {
  let sizeMb = '0 bytes';
  if (size) {
    sizeMb = size / (1024 * 1024);
    if (sizeMb > 1.0) return sizeMb.toFixed(1) + ' MB';
    else {
      let sizeKb = sizeMb * 1024;
      if (sizeKb > 1.0) return sizeKb.toFixed(1) + ' KB';
      else return sizeKb * 1024 + ' bytes';
    }
  }
  return sizeMb;
};

export const getImageFromS3 = (id, prefix = 'base') => {
  return prefix && prefix !== 'base'
    ? `${bucketPath}/${folder}/${prefix}/${id}`
    : `${bucketPath}/${folder}/${id}`;
};

export const getFromS3 = (imagePath = '') => {
  if (!imagePath)
    return null;
  const protocols = ['http:', 'https:', 'file:', 'data:', 'blob:'];
  if (protocols.some(protocol => imagePath.startsWith(protocol)))
    return imagePath;
  return `${bucketPath}/${folder}/${imagePath}`;
};

export const handleAssetImage = (image = '', category = undefined, defaultImage = undefined) => {
  const isSocialOrCDNAvatar =
    (image.startsWith('http')) || (image.startsWith('https'));
  const isLocalImage =
    image &&
    (image.startsWith('/images') ||
      image.startsWith('images/') ||
      image.startsWith('data:'));
  
  if (!image)
    return defaultImage;
  
  return (isLocalImage || isSocialOrCDNAvatar)
    ? image
    : getImageFromS3(image, category);
};

export default {
  checkedLimitSize,
  checkFileExtensions,
  getFileNameBroken,
  getFileExtension,
  formatSize,
  getImageFromS3,
  getFromS3,
  handleAssetImage,
};
import date from './date.utils';
import filters from './filters.utils';
import graphql from './graphql.utils';
import input from './input.utils';
import number from './number.utils';
import object from './object.utils';
import redux from './redux.utils';
import scroll from './scroll.utils';
import storage from './storage.utils';
import strings from './strings.utils';
import styled from './styled.utils';
import uuid from './uuid.utils';

// Exports utils by default
export * from './date.utils';
export * from './filters.utils';
export * from './graphql.utils';
export * from './input.utils';
export * from './number.utils';
export * from './object.utils';
export * from './redux.utils';
export * from './scroll.utils';
export * from './storage.utils';
export * from './strings.utils';
export * from './styled.utils';
export * from './uuid.utils';

export const DEBOUNCE_TIMEOUT = 500;
export const FRONTEND_DEVICE_ID = 'web-frontend-12345';
export const GENERIC_ERROR_MESSAGE = 'An error occurred while processing the request. Try it later';

export default {
  DEBOUNCE_TIMEOUT,
  FRONTEND_DEVICE_ID,
  GENERIC_ERROR_MESSAGE,
  ...date,
  ...filters,
  ...graphql,
  ...input,
  ...number,
  ...object,
  ...redux,
  ...scroll,
  ...storage,
  ...strings,
  ...styled,
  ...uuid,
};
export const scrollHeight = ({
  dataLength,
  limitItems = 6,
  headerHeight = 36,
  rowHeight = 58,
  emptyHeight = 270.38,
  offSet = 2,
}) => {
  let height = headerHeight;
  if (dataLength === 0)
    height += emptyHeight;
  else if (dataLength < limitItems)
    height += ( dataLength * rowHeight ) + ( dataLength * offSet );
  else
    height += ( limitItems * rowHeight );

  return `${ height }px`;
};

export default {
  scrollHeight,
};
import { Observable, Subject } from 'rxjs-compat/Rx';
import socketIOClient from 'socket.io-client';

class SubscriptionService {
    defaultOptions = {
      accessToken: '',
      subscriptionPath: '/api/subscription',
      baseURI: process.env.REACT_APP_BACKEND_URL,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
    };

    rooms = {};
    observable;
    observer;
    events = {};
    connection = new Subject();

    constructor() {
      this.observable = Observable.create((observer) => {
        this.observer = observer;
      }).share();
    }

    connect($options, callback) {
      const options = Object.assign({}, this.defaultOptions, ( $options || {} ));
      if (this.socket && this.options) {
        const lastOptions = Object.keys(this.options);
        if (!lastOptions.some(key => options[key] !== this.options[key])) return;
        this.disconnect();
      }
      this.options = options;

      let query = options.accessToken ? { token: options.accessToken } : {};

      const socket = socketIOClient(options.baseURI, {
        path: options.subscriptionPath,
        query: query,
      });
      socket.on('connect', (data) => {
        this._isConnected = true;
        console.log('socket connect successfully');
        this.connection.next({ event: 'connected' });
        callback && callback({ status: 'connected', data: data });
        if (this._isHasRoomSubscriptions())
          this._joinTo(this._getAllRooms());
      });
      socket.on('disconnect', () => {
        this._isConnected = false;
        console.log('socket disconnected by disconnect event');
        this.connection.next({ event: 'disconnected' });
        callback && callback({ status: 'disconnected' });
        this.connect($options, callback);
      });
      socket.on('error', (e) => {
        this._isConnected = false;
        console.log('socket disconnected by error event');
        this.connection.next({ event: 'disconnected', data: e });
        callback && callback({ error: e, status: 'disconnected' });
        this.connect($options, callback);
      });
      this.socket = socket;
    }

    subscribeSocketStatus(callback) {
      return this.connection.subscribe(callback);
    }

    _isHasRoomSubscriptions() {
      return Object.keys(this.rooms).length;
    }

    _getAllRooms() {
      return Object.keys(this.rooms);
    }

    disconnect() {
      if (!this.getSocket()) return;
      this.getSocket().disconnect();
      this.events = {};
      this.observable = Observable.create((observer) => {
        this.observer = observer;
      }).share();
    }

    isConnected() {
      return this._isConnected;
    }

    getSocket() {
      return this.socket;
    }

    /**
     * Method to broadcast the event to observer
     */
    _notifyAll(event) {
      if (this.observer)
        this.observer.next(event);
    }

    /**
     * Subscribe to an event to specific room. The room is used to minimise the event listeners
     * @param events {Array|String} event subscription
     * @param filter {Object} data filter
     * @param callback {function} function that we will call for the event
     * @return {*[]}
     * */
    subscribe(events, filter, callback) {
      if (Array.isArray(events))
        return events.map((event) => {
          return this._addSubscription(event, filter, callback);
        });
      else
        return this._addSubscription(events, filter, callback);
    }

    _addSubscription(event, filter, callback) {
      this._subscribe(event);
      return this.observable.filter((data) => {
        // Validate that data is not null or undefined
        const filtered = ( data && data.data ) && this._filter(data.data, filter);
        if (data.event === event && filtered)
          console.info('TRIGGER_EVENT_SUBSCRIPTION', data.event, data);
        return data.event === event && filtered;
      }).subscribe(callback);
    }

    _itemFilterCheck(key, data, filter) {
      if (key === '__OR') {
        return !this._orFilter(data, filter.__OR);
      }
      return data[key] !== filter[key];
    }

    _filter(data, filter = {}) {
      return !Object.keys(filter).some((key) => {
        const isArray = Array.isArray(filter[key]);
        if (!isArray) {
          return this._itemFilterCheck(key, data, filter);
        } else {
          return !filter[key].some((item) => data[key] === item);
        }
      });
    }

    _orFilter(data, filter = {}) {
      return Object.keys(filter).some((key) => {
        return data[key] === filter[key];
      });
    }

    /**
     * Method to subscribe to an event with callback
     * @param event
     * @private
     */
    _subscribe(event) {
      if (!this.events[event]) {
        this.socket && this.socket.on(event, (data) => {
          this._notifyAll({ event, data });
        });
        this.events[event] = true;
      }
    }

    /**
     * Joint to specific room. The room is used to minimise the event listeners
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    joinTo(room = {}) {
      const roomId = this._buildRoomId(room);
      if (!roomId) return; // just in case :)
      if (!this.rooms[roomId]) {
        this.rooms[roomId] = 0;
        this._joinTo(roomId);
      }
      this.rooms[roomId]++;
    }

    /**
     * leave to specific room. The room is used to minimise the event listeners
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    leave(room) {
      const roomId = this._buildRoomId(room);
      if (!this.rooms[roomId]) {
        return;
      }
      this.rooms[roomId]--;
      if (this.rooms[roomId] <= 0) {
        this._leave(roomId);
        delete this.rooms[roomId];
      }
    }

    _joinTo(rooms) {
      if (this.isConnected())
        this.socket.emit('subscribe', rooms);
    }

    _leave(rooms) {
      if (this.isConnected())
        this.socket.emit('unsubscribe', rooms);
    }

    /**
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    _buildRoomId(room) {
      if (!room) return;
      let roomId = room.entity;
      if (room.id)
        roomId += `_${ room.id }`;
        //todo: more logic
      return roomId;

    }

    unsubscribe(subscriber) {
      if (subscriber)
        subscriber.unsubscribe();
    }

}

export default new SubscriptionService();

//File work
import moment from 'moment/moment';
import { loadNomenclators } from './utils';

export const roundValue = (balance, decimals = 2) => {
  return Math.round(balance * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const ducValueFormat = (value, decimals = 2) => {
  const decimalsText = [];
  for (let i = 0; i < decimals; i++) {
    decimalsText.push('0');
  }
  if (!value) return `0.${decimalsText.join('')}`;
  const decimalPart = value && `${value}`.split('.')[1];
  if (decimalPart && decimalPart === '00')
    value = `${value}`.replace('.00', '');
  const haveDecimals = value && `${value}`.indexOf('.') !== -1;
  // to 2 decimal digits
  value = roundValue(value);
  if (haveDecimals) {
    const decimals = `${value}`.split('.')[1];
    const amount = `${decimals}`.split('');
    if (amount.length < 2)
      return `${value}0`;
    return `${value}`;
  }
  return `${value}.${decimalsText.join('')}`;
};

/**
 * Find a correct label to currency code
 *
 * @param {Object} record
 * @param {string} source
 * @return {string|*}
 */
export const findCurrencyLabel = (record, source = 'metadata') => {
  let { createdAt, currency = 'DUC' } = record;
  
  let currencyCode = (source === 'metadata') ? record.value : currency;
  // To skip new representation of currency (CUC/CUP)
  if (createdAt && moment(createdAt).year() < 2021) return currencyCode;
  
  const coins = loadNomenclators('currency_codes').find(item => item.code === currencyCode);
  if (coins)
    return coins.symbol;
  return currencyCode;
};

export const formatNumberToMoney = (value) => {
  if (!value) return '';
  const moneyMapper = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return moneyMapper.format(value);
};

export const getNumberFromPose = number => {
  const type = typeof number;
  if (type === 'string') return Number(number.replace('%', ''));
  if (!number) return 0;
  return number;
};

export default {
  roundValue,
  ducValueFormat,
  findCurrencyLabel,
  formatNumberToMoney,
  getNumberFromPose,
};
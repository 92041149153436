import CountryRegionData from 'country-region-data';
import CubaRegionData from './cuba-region-data.json';

export const getAllCountries = () => {
  return CountryRegionData;
};

export const getCountry = (country) => {
  if (country === 'CU' || country === 'Cuba')
    return CubaRegionData;
  return CountryRegionData
    .find(({ countryShortCode, countryName }) => [countryShortCode, countryName].includes(country));
};

export const getStatesByCountry = (country) => {
  const countryFounded = getCountry(country);
  if (!countryFounded)
    return [];
  const { regions = [] } = countryFounded;
  return regions;
};

export const getStateByCountryAndState = (country, state) => {
  const states = getStatesByCountry(country);
  return states.find(({ name, shortCode }) => [name, shortCode].includes(state));
};

export const getMunicipalitiesByCountryAndState = (country, state) => {
  const stateFounded = getStateByCountryAndState(country, state);
  if (!stateFounded)
    return [];
  const { municipalities = [], cities = [] } = stateFounded;
  if (municipalities.length)
    return municipalities;
  return cities;
};

//General
const makeReplacer = () => {
  let dateTime = Date.now();
  return (char) => {
    const random = (dateTime + Math.random() * 16) % 16 | 0;
    dateTime = Math.floor(dateTime / 16);
    const replaceChar = char === 'x' ? random : ((random & 0x3) | 0x8);
    return replaceChar.toString(16);
  };
};

export const createUUID = (template = 'xxxxxxxxxxxx-4xxx-yxxx-xxxxxxxxxxxx', searchRegex = /[xy]/g) => {
  const replacer = makeReplacer();
  return template.replace(searchRegex, replacer);
};

export const createUUIDShort = (length = 24) => {
  const searchValue = /[xy]/g;
  const replacer = makeReplacer();
  return String()
    .padEnd(length, 'x')
    .replace(searchValue, replacer);
};

export default {
  createUUID,
  createUUIDShort,
};